<template>
  <div>
    <nav-header fixed title=""/>
    <van-form @submit="onSubmit">
      <div class="padding-top-15">
        <van-cell-group inset>
          <van-field
              v-model="form.ywht_name"
              required
              label="合同名称"
              placeholder="请输入合同名称"
          />
        </van-cell-group>
      </div>
      <div class="padding-top-15">
        <van-cell-group inset>
          <van-field
              required
              label="上传文件"
          >
            <template #input>
              <add-img file-type="image/*,.pdf,.doc,.docx,.xml,.zip,.xlsx" :show-download="false" :length="6" v-model:form="form.file"/>
            </template>
          </van-field>
        </van-cell-group>
      </div>
      <div class="form-btn">
        <van-button round block type="primary" native-type="submit">
          提交
        </van-button>
      </div>
    </van-form>
  </div>
</template>

<script>
import NavHeader from "../../components/top/NavHeader";
import AddImg from "../../components/file/AddImg";
import http from "../../api/http";
import XEUtils from "xe-utils";
import din_shi from "../../api/din_shi";

export default {
  name: 'AddHetong',
  components: {AddImg, NavHeader},
  mixins: [],
  data() {
    return {
      form: {
        file: [],
        ywht_id: this.$route.params.ywht_id
      }
    }
  },
  mounted() {
    this.getInfo()
  },
  methods: {
    onSubmit() {
      let data = JSON.parse(JSON.stringify(this.form))
      data.file = XEUtils.map(data.file, n => {
        return {
          name: n.name,
          url: n.url
        }
      })
      http.post('/gzhphp/user_xiang_mu/addHeTongFile', data).then(da => {
        if (da.code === 1) {
          din_shi.success(da.msg).then(() => {
            this.$router.back(-1)
          })
        }
      })
    },
    getInfo() {
      http.get('/gzhphp/user_xiang_mu/getXiangMuHeTongInfo', {
        params: {ywht_id: this.form.ywht_id}
      }).then(da => {
        if (da.code === 1) {
          this.form.file = XEUtils.map(da.data.join_fu_jian, n => {
            return {
              name: n.file_name,
              url: n.url
            }
          })
          this.form.ywht_name = da.data.name
        }
      })
    }
  },
  watch: {},
  computed: {}
}
</script>

<style scoped>

</style>
